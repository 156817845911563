<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-12">
		<!-- Таблица -->
		<TableReviews />
	</div>
</template>

<script>
import TableReviews from './TableReviews.vue';

export default {
	name: 'Main',
	components: { TableReviews }
};
</script>

<style scoped></style>
