import { compareDesc } from '@/store/store';

export default {
	getFeedbacks(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('Anketa/GetFeedbacks', parametrs)
				.then((result) => {
					result.data.data.forEach((x) => {
						x.feedBackList.sort((a, b) => compareDesc(a.feedBackDate, b.feedBackDate));
					});

					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
